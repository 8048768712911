import { render, staticRenderFns } from "./acc-inbox.vue?vue&type=template&id=20af380c&scoped=true&"
import script from "./acc-inbox.vue?vue&type=script&lang=js&"
export * from "./acc-inbox.vue?vue&type=script&lang=js&"
import style0 from "./acc-inbox.vue?vue&type=style&index=0&id=20af380c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20af380c",
  null
  
)

export default component.exports